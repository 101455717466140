body {
  width: 100vw;
  height: 100vh;

  &.home {
    overflow: hidden;

    header {
      .header__menu--nav {
        .header__menu {
          &--link {
            color: var(--white);
          }
        }
      }

      .header__logo {
        &--svg {
          .logo__path--color {
            fill: var(--white);
          }
        }
      }
    }

    main {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: -2;
      background: url(../img/sonrisamallorca-home_bg.jpg) center;
      background-size: cover;
      display: flex;
      justify-content: center;
      .hero__headline{
        color: var(--white);
        text-align: center;
        transform: translateY(30%)
      }
    }

    .hero__smile {
      @media only screen and (max-width: $screenMd) {
          bottom: 9rem
      }
      path {
        fill: var(--white);
      }
    }
  }
}
