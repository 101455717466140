.contact {
  background-color: var(--primary);

  header {
    background-color: var(--primary);
    color: var(--white);

    .header__logo {
      &--svg {
        .logo__path--color {
          fill: var(--white);
        }
      }
    }
  }

  main {
    //height: 100%;
    color: var(--white);
    padding-bottom: 4rem;
    .hero{
      margin-top: 4rem;
      @media only screen and (min-width: $screenMd) {
        margin-top: 0;
      }
      &__headline{
        color: var(--white);
        margin-bottom: 2rem;
      }
    }

    .contact__container {
      position: relative;
      padding: 5%;
      @media only screen and (min-width: $screenMd) {
        padding: 8rem 5%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr auto;
        grid-column-gap: 2.4rem;
      }
      &--info{
        @media only screen and (min-width: $screenMd) {
          grid-column: 1 / 5;
        }
        address{
          font-style: normal;
          .contact__container__sonrisa{
            display: block;
            font-family: var(--font-medium);
          }
        }
        ul{
          margin-top: 4.3rem;
          li{
            margin-bottom: 1rem;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }

    .contact__form {
      width: 100%;
      @extend %form_inputs;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      color: var(--white);
      margin-top: 8rem;
      @media only screen and (min-width: $screenMd) {
        margin-top: 0;
        grid-column: 5 / 13;
      }
      .form__fields {
        display: flex;
        flex-direction: column;
        order: 2;
        width: 100%;
        @media only screen and (min-width: $screenMd) {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
        }

        .form__field {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 2.4rem;
          @media only screen and (min-width: $screenMd) {
            width: 48%;
          }

          input, textarea {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid var(--white);
            margin-bottom: 1.5rem;
            color: var(--white);
            padding-top: 1rem;
            padding-bottom: 0.5rem;
          }

          textarea {
            white-space: pre-wrap;
          }
          &.field--message{
            width: 100%;
            textarea{
              width: 100%;
            }
          }
        }
      }

      .form__checkbox {
        align-items: center;
        display: flex;
        margin-top: 2rem;
        order: 3;
        height: max-content;
        width: 100%;
        .custom-checkbox-label {
          //margin-left: 1rem;
          input {
            left: 0;


          }

          .custom-checkbox-button {
            border: 2px solid var(--white)
          }
        }

        .consent__text {
          margin-left: 2rem;
          color: var(--white);
          line-height: 1.5;
        }
      }

      .form--link {
        text-decoration: underline;
      }

      .form__submit {
        background-color: transparent;
        font-family: var(--font-medium);
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-top: 2rem;
        letter-spacing: 0.2em;
        //@extend %link_underline;
        order: 5;
        color: var(--white);
        border: 2px solid white;
        padding: 1rem 2rem;
        cursor: pointer;
        @media only screen and (min-width: $screenMd) {
          font-size: 1.6rem;
        }

        &:after {
          bottom: -2px;
        }

        &.disabled {
          opacity: 0.2 !important;
        }

        &:hover{
          background-color: var(--white);
          color: var(--primary);
        }
      }

      .server-response {
        order: 4;
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
}
