.aboutus {
  header{
    background-color: var(--primary);
    color: var(--white);
    .header__logo{
      &--svg{
        .logo__path--color{
          fill: var(--white);
        }
      }
    }
  }
  main {
    height: auto;
    display: flex;
    flex-direction: column;

    .hero {
      position: relative;
      min-height: calc(var(--vh) - var(--headerh) - 5rem);

      .hero__headline {
        color: var(--white);
        @media only screen and (min-width: $screenMd) {
          padding-right: 25%;
        }
      }

      .hero__smile {
        path {
          fill: var(--white);
        }
      }
    }

    .aboutus__discover {
      position: relative;
      padding: 5%;
      @media only screen and (min-width: $screenMd) {
        padding: 8rem 5%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr auto;
        grid-column-gap: 2.4rem;
      }

      .aboutus___discover--header {
        @extend %hero-headline;
        color: var(--primary);
        padding: 0;
        margin-bottom: 4rem;

        @media only screen and (min-width: $screenMd) {
          grid-column: 5 / 12;
          grid-row: 1;
        }
      }
      .about__discover--photo{
        margin-bottom: 4rem;
        @media only screen and (min-width: $screenMd) {
          grid-column: 1 / 5;
          grid-row: 1 / 3;
          //max-height: 70vh;
          height: 100%;
          object-fit: cover;
          margin-bottom: 0;
        }
      }
      .aboutus__discover--content {
        padding-right: 5%;

        &.column-1 {
          @media only screen and (min-width: $screenMd) {
            grid-column: 5 / 9;
            grid-row: 2;
          }
        }

        &.column-2 {
          @media only screen and (min-width: $screenMd) {
            grid-column: 9 / 13;
            grid-row: 2;
          }
        }

        .aboutus__discover--paragraph {
          margin-bottom: 1.5rem;
          line-height: 1.5;
          @media only screen and (min-width: $screenMd) {
              margin-bottom: 0;
          }
          &:first-child{
            @media only screen and (min-width: $screenMd) {
              margin-bottom: 1rem;
            }
          }
        }
      }

      .hero__smile {
        left: 10%;
        bottom: 8rem;

        path {
          fill: var(--secondary)
        }
      }
    }
  }
}
