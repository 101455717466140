footer.landing {
  width: 100vw;
  min-height: 10vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 10rem 0;
  transform: translateX(-50%);
  //z-index: -1;
  @media only screen and (min-width: $screenMd) {
    padding: 5vh 0;
  }

  ul {
    li {
      text-align: center;
      margin-top: 1rem;
      color: var(--white);

      a {
        color: var(--white);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

footer {
  width: 100%;
  border-top: 0.4rem solid var(--secondary);
  min-height: 20vh;
  text-align: center;
  padding: 9.5rem 5% 6.5rem;
  font-family: var(--font-book);
  background-color: var(--white);
  @media only screen and (min-width: $screenMd) {
    padding: 9.5rem 0 6.5rem;
  }
  .footer__logo {
    .header__logo--svg {
      width: 14rem;
      margin-bottom: 4.5rem;
      .logo__secondary--color{
        fill: var(--secondary)
      }
    }
  }
  address{
    font-style: normal;
    .footer__sonrisa{
      display: block;
      font-family: var(--font-medium);
    }
  }
  ul{
    margin-top: 4rem;
    li{
      margin-bottom: 1rem;
      a{
        &:hover{
          text-decoration: underline;
        }
      }
      &.footer__legal{
        margin-top: 3.2rem;
        font-size: 1.2rem;
      }
    }
  }
}
