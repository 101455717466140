@font-face {
  font-family: 'Stolzl-Book';
  src: url('../fonts/Stolzl-Book.woff2') format('woff2'),
  url('../fonts/Stolzl-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Stolzl-Medium';
  src: url('../fonts/Stolzl-Medium.woff2') format('woff2'),
  url('../fonts/Stolzl-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Stolzl-Regular';
  src: url('../fonts/Stolzl-Regular.woff2') format('woff2'),
  url('../fonts/Stolzl-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-regular: 'Stolzl-Regular', sans-serif;
  --font-book:  'Stolzl-Book', sans-serif;
  --font-medium:  'Stolzl-Medium', sans-serif;
}

body{
  font-family: var(--font-regular);
  color: var(--primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  @media only screen and (min-width: $screenXlg) {
    font-size: 1.8rem;
  }
}

%menu{
  font-size: 2.4rem;
  @media only screen and (min-width: $screenMd) {
    font-size: 1.8rem;
  }
}

%hero-headline{
  font-size: 3.2rem;
  line-height: 1.2;
  color: var(--primary);
  padding: 0 5%;
  font-family: var(--font-book);
  @media only screen and (min-width: $screenMd) {
    font-size: 5.6rem;
  }
  &:lang(de){
    @media only screen and (min-width: $screenMd) {
        font-size: clamp(3.6rem, 3vw, 4.8rem);
    }
  }
}

%form_inputs{
  line-height: 1.4;
  font-size: 1.6rem;
}

%properties__title{
  font-size: 2.4rem;
  line-height: 1.2;
  @media only screen and (min-width: $screenMd) {
      font-size: 3.2rem;
  }
}
