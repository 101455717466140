.hamburger__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  user-select: none;
  height: 2.3rem;
  width: 4.2rem;
  margin-top: 3rem;
  @media only screen and (min-width: $screenMd) {
    display: none;
  }
}

#Menu__Check {
  height: 2.3rem;
  width: 4.2rem;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;


  &:checked ~ .menuSpan {
    opacity: 1;
    transform: rotate(45deg) translate(-7px, -6px);
    transition: transform 0.3s;
    height: 0.3rem;
    background-color: var(--white);
    border-radius: 0.3rem;
    @media only screen and (min-width: $screenMd) {
      transform: rotate(45deg) translate(0, 0);
    }
  }

  &:checked ~ .menuSpan:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
    transition: transform 0.3s;
  }

  &:checked ~ .menuSpan:nth-last-child(2) {
    //transform: rotate(-45deg) translate(1px, -2px);
    transform: rotate(-45deg) translate(0, 0);
    transition: transform 0.3s;
    @media only screen and (min-width: $screenMd){
      transform: rotate(-45deg) translate(-6px, 6px);
    }
  }

  &:checked ~ ul {
    transform: none;
    transition: transform 0.3s;
  }

  &:not(:checked) ~ .menuSpan {
    transform: rotate(0) translate(0, 0);
    transition: transform 0.3s;
    height: 0.3rem;
    background-color: var(--white);
    border-radius: 0.3rem;
  }
}
