.services {
  #Menu__Check:not(checked) ~ .menuSpan {
    background-color: var(--primary);
  }

  header {
    .header__menu--item {
      color: var(--white);
      @media only screen and (min-width: $screenMd) {
        color: var(--primary);
      }

      .header__menu--link {
        &:after {
          background-color: var(--primary);
        }
      }
    }
  }

  &.menu-open {
    header {
      .hamburger__menu {
        #Menu__Check:checked ~ .menuSpan {
          background-color: var(--white);
        }
      }
    }
  }

  main {
    height: auto;
    display: flex;
    flex-direction: column;

    .hero {
      position: relative;
      min-height: calc(var(--vh) - var(--headerh) - 5rem);

      .hero__headline {
        @media only screen and (min-width: $screenMd) {
          padding-right: 25%;
        }
      }
    }

    .services__full-img {
      width: 100%;
      max-height: 80vh;
      object-fit: cover;
    }

    .services__discover {
      background-color: var(--primary);
      color: var(--white);
      position: relative;
      padding: 20% 5% 30%;
      @media only screen and (min-width: $screenMd) {
        padding: 8rem 0;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 2.4rem;
      }

      .services__discover--header {
        @extend %hero-headline;
        color: var(--white);
        padding: 0;
        margin-bottom: 4rem;
        @media only screen and (min-width: $screenMd) {
          margin-bottom: 20rem;
        }

        @media only screen and (min-width: $screenMd) {
          grid-column: 5 / 13;
        }
      }

      .service__discover--content {
        padding-right: 5%;

        &.column-1 {
          @media only screen and (min-width: $screenMd) {
            grid-column: 5 / 9;
          }
        }

        &.column-2 {
          @media only screen and (min-width: $screenMd) {
            grid-column: 9 / 13;
          }
        }

        .services__discover--paragraph {
          margin-bottom: 1.5rem;
          line-height: 1.5;
          @media only screen and (min-width: $screenMd) {
            margin-bottom: 0;
          }

          &:first-child {
            @media only screen and (min-width: $screenMd) {
              margin-bottom: 1rem;
            }
          }
          &:nth-child(2) {
            @media only screen and (min-width: $screenMd) {
              margin-bottom: 1rem;
            }
          }
        }
      }

      .hero__smile {
        left: 17%;
        bottom: 4rem;
        @media only screen and (min-width: $screenMd) {
          left: 10%;
          bottom: 8rem;
        }

        path {
          fill: var(--secondary)
        }
      }
    }
  }
}
