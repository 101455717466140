.property{
  #Menu__Check:not(checked) ~ .menuSpan {
    background-color: var(--primary);
  }
  header {
    .header__menu--item {
      color: var(--white);
      @media only screen and (min-width: $screenMd) {
        color: var(--primary);
      }
      .header__menu--link {
        &:after {
          background-color: var(--primary);
        }
      }
    }
  }
  &.menu-open{
    header{
      .hamburger__menu {
        #Menu__Check:checked ~ .menuSpan {
          background-color: var(--white);
        }
      }
    }
  }
  .hero{
      margin-bottom: 8rem;
    @media only screen and (min-width: $screenMd) {
      margin-bottom: 12rem;
    }
    .hero__headline{
      font-size: 2.8rem;
      margin-top: 2rem;
      @media only screen and (min-width: $screenLg) {
        padding-right: 48%;
      }
      @media only screen and (min-width: $screenXlg) {
        padding-right: 40%;
      }
    }
  }
  &__content{
    padding: 5%;
    @media only screen and (min-width: $screenMd) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 1fr auto;
      grid-column-gap: 2.4rem;
    }
    .property__content--main, .property__content--mainimg{
      margin-bottom: 2rem;
      width: 100%;
      @media only screen and (min-width: $screenMd) {
        grid-column: 1 / 7;
        margin-bottom: 0;
        width:100%;
      }
    }
    &--about{
      @media only screen and (min-width: $screenMd) {
          grid-column: 7 / 12;
      }
    }
    &--paragraph{
      margin-bottom: 2rem;
      &.txt--highlight{
        font-size: 2rem;
        font-family: var(--font-medium);
        @media only screen and (min-width: $screenMd) {
          font-size: 2.4rem;
        }
        a{
          text-decoration: underline;
        }
      }
    }
    &--img{
      margin-top: 2.4rem;
      @media only screen and (min-width: $screenMd) {
          grid-column: span 4;
      }
    }
    &--link{
      text-decoration: underline;
    }
  }
  &__info{
    background-color: var(--primary);
    color: var(--white);
    position: relative;
    padding: 15% 5% 20vh;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $screenMd) {
        padding: 13rem 5% 7rem;
      align-items: flex-end;
    }
    &--header{
      @extend %hero-headline;
      color: var(--white);
      padding: 0;
      width: 100%;
      margin-bottom: 5rem;
      @media only screen and (min-width: $screenMd) {
        margin-bottom: 20rem;
        width: 50%;
      }
    }
    &--list{
      width: 100%;
      @media only screen and (min-width: $screenMd) {
        width: 50%;
      }
    }
    &--item{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--white);
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      &.property--price{
        margin-top: 4rem;
      }
    }
    &--data{
      text-align: right;
    }
    .hero__smile{
      left: 17%;
      bottom: 4rem;
      @media only screen and (min-width: $screenMd) {
        left: 10%;
        bottom: 8rem;
      }
      path{
        fill: var(--secondary);
      }
    }
  }

  &__container{
    padding: 5% 5% 30%;
    position: relative;
    @media only screen and (min-width: $screenMd) {
      padding: 5% 5% 15%;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 1fr auto;
      grid-column-gap: 2.4rem;
    }
    &--item{
      width: 100%;
      margin-bottom: 8vh;
      display: block;
      @media only screen and (min-width: $screenMd) {
        grid-column: span 6;
        margin-bottom: 5vh;
      }
    }
    &--img{
      width: 100%;
    }
    &--title{
      margin: 1rem 0 2rem;
      @extend %properties__title;
      &:hover{
        text-decoration: underline;
      }
      @media only screen and (min-width: $screenMd) {
          padding-right: 5%;
      }
    }
  }
}
