.hero__headline {
  @extend %hero-headline;
  margin-top: 5%;
  &--medium{
    font-family: var(--font-medium);
  }
}
.hero--block{
  @media only screen and (min-width: $screenMd) {
      display: block;
  }
}
.contact--block{
  @media only screen and (max-width: $screenMd) {
      display: block;
  }
}

.hero__smile {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  width: 8rem;
  z-index: 99;
  @media only screen and (min-width: $screenMd) {
    width: 14rem;
    left: 92%;
  }

  path {
    fill: var(--primary);
  }
}

.bg_color{
  &-primary{
    background-color: var(--primary);
  }
}
