.legal{
  header{
    .header__menu--item{
      color: var(--primary);
      &:after {
        background-color: var(--primary);
      }
    }
  }
  main{
    margin: 0 20% 20vh;
    .hero{
      .hero__headline{
        padding: 0 0 5rem;
      }
      .hero__smile{
        position: fixed;
      }
    }
    h3{
      font-family: var(--font-medium);
      margin: 4rem 0 1rem;
    }
    p{
      margin-bottom: 1rem;
      font-family: var(--font-regular);
    }
  }
}
