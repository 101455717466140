header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vw 5%;
  @media only screen and (min-width: $screenMd) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: auto;
  }

  .header__menu--nav {
    display: none;

    color: var(--white);
    @media only screen and (min-width: $screenMd) {
      display: flex;
      background-color: transparent;
    }

    &.menu-open {
      display: flex;
    }

    .header__menu {
      display: flex;

      &--item {
        @extend %menu;

        &.menu__lang {
          display: flex;

          .menu__lang--item {
            display: flex;
            align-items: center;

            &:first-child {
              a{
                //&:after {
                //  display: block;
                //  content: '/';
                //  padding: 0 1rem;
                //}
              }
            }

          }
          span{
            display: block;
            padding: 0 1rem;
          }
        }
      }
      &--link{
        @extend %link_underline;
      }
    }

    &.header__menu--left {
      .header__menu--item {
        &:not(last-child) {
          margin-right: 3vw
        }
      }
    }

    &.header__menu--right {
      .header__menu--item {
        &:not(last-child) {
          margin-left: 3vw
        }
      }
    }
  }

  .header__logo {
    width: 50vw;
    margin-top: 3rem;
    @media only screen and (min-width: $screenMd) {
      width: 15vw;
      margin-top: 0;
    }

    .logo__path--color {
      fill: var(--primary);
    }

    .logo__secondary--color {
      fill: var(--secondary);
    }
  }

  .hero__smile--header {
    display: none;

    path {
      fill: var(--white);
    }
  }
}

.menu-open {
  header {
    background-color: var(--primary);
    height: 100vh;
    z-index: 15;
    position: relative;
    @media only screen and (min-width: $screenMd) {
      background-color: transparent;
      height: auto
    }

    .header__menu--nav {
      &.header__menu--left, &.header__menu--right {
        .header__menu {
          flex-direction: column;
          align-items: center;

          &--item {
            width: max-content;
            margin-right: 0;
            margin-bottom: 3rem;
          }
        }
      }
    }

    .header__logo {
      .header__logo--svg {
        .logo__path--color {
          fill: var(--white);
        }
      }
    }

    .hero__smile--header {
      display: flex;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 9rem;
    }
  }
}

@media only screen and (max-width: $screenMd) {
  .header__logo {
    order: 1;
  }
  .hamburger__menu {
    order: 2;
  }
  .header__menu--left {
    order: 3;
    margin-top: 15vh;
  }
  .header__menu--right {
    order: 4;
  }
}
